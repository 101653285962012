import { memo, useRef, useMemo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import useDeps from '@Contexts/DI/useDeps';
import ProductSection from './elements/ProductSection';
import CatalogContent from './elements/CatalogContent';
import ProductItem from './elements/ProductItem';
import BannerItem from './elements/BannerItem';
import styles from './ProductMixedCatalog.module.css';

import type { InfiniteData } from '@tanstack/react-query';
import type { FC, HTMLAttributes, ReactElement, MouseEvent } from 'react';
import type { Orientation } from '@Types/Base';
import type { ProductData } from '@Types/Product';
import type {
  CatalogData,
  RenderProductParams,
  DefaultStructureData,
  BannerStructureData,
} from '@Types/Catalog';
import type { CategorySection } from '@Types/Category';
import type { InlineBannerData } from '@Promo/typings';
import type { SearchProductsResultData } from '@Types/Search';

export type RenderProduct = (params: RenderProductParams) => ReactElement;

export interface RenderItemData {
  id: string;
  content: ReactElement;
  orientation?: Orientation;
  double?: boolean;
  bannerRow?: boolean;
  page?: number;
}

export interface RowData {
  needPlaceholder: boolean;
  elems: RenderItemData[];
  page: number;
}

export type RenderData = {
  rows: RowData[];
  key?: string;
  title?: string;
  delimiter?: boolean;
}[];

export interface ProductMixedCatalogProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  analyticsTitle: string;
  category: InfiniteData<CatalogData> | InfiniteData<SearchProductsResultData>;
  autoload?: boolean;
  banners?: InlineBannerData[];
  defaultStructure?: BannerStructureData;
  cnItem?: string;
  isInSpotlight?: boolean;
  renderProduct?: RenderProduct;
  onChangeVisibleRow?: (page: number) => void;
  categoryName?: string;
}

interface ProductByPagesData extends ProductData {
  page: number;
}

const ProductMixedCatalog: FC<ProductMixedCatalogProps> = (props) => {
  const {
    className,
    analyticsTitle,
    category,
    banners = [],
    defaultStructure = { left: [], right: [] },
    isInSpotlight,
    renderProduct,
    onChangeVisibleRow,
    categoryName,
    ...restProps
  } = props;
  const { isMobile, isMobileM, isDesktop } = useMedias();
  const ref = useRef<HTMLDivElement>();
  const { analytics } = useDeps();

  const { products, schemas } = useMemo(() => {
    const allProducts: Record<number, ProductData[]> = {};
    const allSchemas: Record<string, CategorySection> = {};
    category.pages.forEach((page) => {
      allProducts[page.page] = page.products;

      page.productSchemas?.forEach((schema) => {
        const { title, statuses } = schema;
        const key = title || 'empty';

        if (!allSchemas[key]) {
          allSchemas[key] = {
            title,
            statuses: new Set(statuses),
          };

          return;
        }

        schema.statuses.forEach((status) => {
          for (const schemaKey of Object.keys(allSchemas)) {
            if (allSchemas[schemaKey].statuses.has(status)) return;
          }

          allSchemas[key].statuses.add(status);
        });
      });
    });
    return { products: allProducts, schemas: allSchemas };
  }, [category.pages]);

  const countProductsInRow: Partial<Record<DefaultStructureData, number>> = useMemo(() => {
    if (isMobile) {
      return {
        'all-products': isInSpotlight ? 1 : 2,
        'banner-right': 0,
        'banner-left': 0,
      };
    }

    if (isMobileM) {
      return {
        'all-products': 2,
        'banner-right': 0,
        'banner-left': 0,
      };
    }

    if (isDesktop) {
      return {
        'all-products': 3,
        'banner-right': 1,
        'banner-left': 1,
      };
    }

    return {
      'all-products': 4,
      'banner-right': 2,
      'banner-left': 2,
    };
  }, [isMobile, isMobileM, isDesktop, isInSpotlight]);

  const countPlacesForBanners = useCallback(() => {
    const bannerPlaces = {
      'banner-left': 2,
      'banner-right': 2,
    };
    const placesForBanners =
      (defaultStructure.left?.length || 0) * bannerPlaces['banner-left'] +
      (defaultStructure.right?.length || 0) * bannerPlaces['banner-right'];

    return placesForBanners;
  }, [defaultStructure]);

  const slicedStructure = useCallback(
    (list: ProductData[], showBanner: boolean) => {
      const newItems: DefaultStructureData[] = [];
      const placesForBanners = countPlacesForBanners();
      const countRows = Math.ceil(
        (list.length + placesForBanners) / countProductsInRow['all-products'],
      );

      for (let i = 0; i < countRows; i += 1) {
        if (!showBanner) {
          newItems.push('all-products');
          continue;
        }
        const isLeft = defaultStructure.left?.some((item) => item === i);
        const isRight = defaultStructure.right?.some((item) => item === i);

        if (isLeft) {
          newItems.push('banner-left');
          continue;
        }
        if (isRight) {
          newItems.push('banner-right');
          continue;
        }
        newItems.push('all-products');
      }

      return newItems;
    },
    [defaultStructure, countProductsInRow, countPlacesForBanners],
  );

  const handleClickProductLink = useCallback(
    (e: MouseEvent, data: { product: ProductData; position: number; listTitle: string }) => {
      const { product, position, listTitle } = data;

      analytics.dispatchEvent('card.click', {
        cardType: 'product',
        listTitle,
        id: product.id,
        card: product,
        position,
      });
    },
    [analytics],
  );

  const prepareRenderedProductsRows = useCallback(
    (
      productsList: Record<number, ProductData[]>,
      analyticsSchemaTitle?: string,
      showBanner = true,
    ) => {
      const result: RowData[] = [];
      let indexBanner = 0;
      let indexProduct = 0;
      const allProducts: ProductByPagesData[] = [];

      Object.entries(productsList).forEach(([page, list]) => {
        list.forEach((item) => allProducts.push({ ...item, page: +page }));
      });

      slicedStructure(allProducts, showBanner).forEach((row, rowIndex) => {
        const countProducts = countProductsInRow[row];
        const offset = indexProduct + countProducts;
        const chunkProducts = allProducts.slice(indexProduct, offset);
        const orientation = { landscape: false, portrait: false };
        const hasReadySolutions = chunkProducts.some(
          (product) => product.readySolutions?.colors?.length > 0,
        );

        chunkProducts.forEach((product) => {
          if (product.orientation === 'landscape') {
            orientation.landscape = true;
          }
          if (product.orientation === 'portrait') {
            orientation.portrait = true;
          }
          if (orientation.landscape && orientation.portrait) {
            return;
          }
        });
        const hasDifferentOrientation = orientation.landscape && orientation.portrait;
        const banner = banners[indexBanner];
        const elems: RenderItemData[] = [];

        indexProduct += chunkProducts.length;

        chunkProducts.forEach((product, itemIndex) => {
          const index = rowIndex * chunkProducts.length + itemIndex;

          elems.push({
            id: `${product.id}`,
            orientation: product.orientation,
            page: product.page,
            content: (
              <ProductItem
                key={product.id}
                index={index}
                analyticsTitle={analyticsSchemaTitle}
                card={product}
                renderProduct={renderProduct({
                  product,
                  view: isInSpotlight ? undefined : 'mini',
                  hasReadySolutions,
                  imageLazyLoading: rowIndex !== 0,
                  onClickProductLink: (e: MouseEvent) =>
                    handleClickProductLink(e, {
                      product,
                      position: index,
                      listTitle: analyticsSchemaTitle,
                    }),
                })}
              />
            ),
          });
        });

        switch (row) {
          case 'banner-right':
            if (!banner) return;

            if (chunkProducts.length === countProducts) {
              elems.push({
                id: `banner_${indexBanner}`,
                double: true,
                content: (
                  <BannerItem
                    index={indexBanner}
                    banner={banner}
                    categoryName={categoryName}
                    analyticsTitle={`${analyticsSchemaTitle}_promo`}
                  />
                ),
              });
              indexBanner += 1;
            }
            break;

          case 'banner-left':
            if (!banner) return;

            if (chunkProducts.length > 0 || countProducts === 0) {
              elems.unshift({
                id: `banner_${indexBanner}`,
                double: true,
                content: (
                  <BannerItem
                    index={indexBanner}
                    banner={banner}
                    categoryName={categoryName}
                    analyticsTitle={`${analyticsSchemaTitle}_promo`}
                  />
                ),
              });
              indexBanner += 1;
            }
            break;

          default:
        }

        result.push({
          needPlaceholder:
            hasDifferentOrientation || row === 'banner-left' || row === 'banner-right',
          elems,
          page: elems.find((elem) => elem.page)?.page,
        });
      });

      return result;
    },
    [
      slicedStructure,
      countProductsInRow,
      banners,
      renderProduct,
      isInSpotlight,
      handleClickProductLink,
      categoryName,
    ],
  );

  const data: RenderData = useMemo(() => {
    const hasSchemas = Object.keys(schemas).length > 0;

    const filterByStatus = (statuses: Set<string>, array: Record<number, ProductData[]>) => {
      const result = {};
      Object.entries(array).map(([page, list]) => {
        result[page] = list.filter((item) => statuses.has(item.status));
      });

      return result;
    };

    if (!hasSchemas) {
      return [
        {
          key: 'no-schema',
          rows: prepareRenderedProductsRows(products, analyticsTitle),
        },
      ];
    }

    return Object.keys(schemas).map((schemaKey, schemaIndex, keys) => {
      const list = filterByStatus(schemas[schemaKey].statuses, products);
      const title = schemas[schemaKey].title;

      return {
        key: schemaKey,
        rows: prepareRenderedProductsRows(
          list,
          `${title ? title : analyticsTitle}`,
          schemaIndex === 0,
        ),
        title: schemas[schemaKey].title,
        delimiter: schemaIndex !== keys.length - 1,
      };
    });
  }, [prepareRenderedProductsRows, analyticsTitle, products, schemas]);

  return (
    <div
      {...restProps}
      className={cn(
        styles.catalog,
        {
          [styles.isInSpotlight]: isInSpotlight,
        },
        className,
      )}
      ref={ref}
    >
      <div className={styles.list}>
        {data.map((section) => {
          return (
            <ProductSection
              key={section.key}
              title={section.title}
              cnArea={styles.delimiter}
              delimiter={section.delimiter}
            >
              <CatalogContent
                rows={section.rows}
                isInSpotlight={isInSpotlight}
                onChangeVisibleRow={onChangeVisibleRow}
              />
            </ProductSection>
          );
        })}
      </div>
    </div>
  );
};

export default memo(ProductMixedCatalog);
